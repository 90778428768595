import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import withAuth from '../../../../../../../../../utils/axios/withAuth';
import { ACTIVE_COUPON } from '../../../../../../../../../utils/coupon';
import TitlePlanSelect from '../Title';
import PlanItem from '../PlanItem';
import useStyles from './styles';
import { useAppDispatch } from '../../../../../../../../../hooks/useAppDispatch';
import { getPlans } from '../../../../../../../../../stories/actions/payment';
import { useReduxState } from '../../../../../../../../../hooks/useReduxState';

let stripe;

const keys = {
  live: 'pk_live_51HiMQkCh5qCS77obLnokWCFM1PbNO7GN1lxLyb92SEGel8C56eahoSddx8Le0gh5EZVvpSbBxyRiv0iizx7cmtiH00X15HmHg9',
  test: 'pk_test_51HiMQkCh5qCS77obmsIUxOvGLPittmEG4gsizSct7rqQYskaFIZGl28XoKtRY4ZhBXoRmqzvjREFmfB7XFcBpkQN00p8R5CVrj'
};

const formatPrice = (amount: number) =>
  String((amount / 100).toFixed(2)).replace('.', ',');

function getClientReferenceId() {
  return (
    // @ts-ignore
    (window.Rewardful && window.Rewardful.referral) ||
    'checkout_' + new Date().getTime()
  );
}

const PlanContainer = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { payment } = useReduxState();

  useEffect(() => {
    dispatch(getPlans('premium'));
    loadStripe(
      ['app-stg.clarice.ai', 'tests.clarice.ai', 'local-host'].includes(
        window.location.hostname
      )
        ? keys.test
        : keys.live,
      {
        locale: 'pt-BR'
      }
    ).then(s => {
      stripe = s;
    });
    // .catch(e => console.log(e));
  }, []);

  const onRedirectToCheckout = async (priceId: string, type) => {
    const res = await withAuth().post(
      '/payments/create-checkout-session',
      ACTIVE_COUPON === 'NO_DISCOUNT' || type !== 'yearly'
        ? {
            priceId,
            client_reference_id: getClientReferenceId(),
            code: ACTIVE_COUPON
          }
        : {
            priceId,
            code: ACTIVE_COUPON,
            client_reference_id: getClientReferenceId()
          }
    );

    await stripe.redirectToCheckout(res.data);
  };

  return (
    <Box className={classes.container}>
      <TitlePlanSelect title={'Selecione o plano'} key={'title'} />

      {payment.plans && payment.plans.month && (
        <PlanItem
          key={payment.plans.month.created}
          name={'MENSAL'}
          monthPrice={formatPrice(payment?.plans?.month?.amount)}
          automaticRenovation
          first
          selectPlan={() =>
            onRedirectToCheckout(payment.plans.month.id, 'monthly')
          }
        />
      )}

      {payment.plans && payment.plans.year && (
        <PlanItem
          key={payment.plans.year.id}
          name={'ANUAL'}
          monthPrice={formatPrice(payment.plans.year.amount / 12)}
          yearPrice={formatPrice(payment.plans.year.amount)}
          discount={
            payment.plans.year.amount_off
              ? formatPrice(
                  payment.plans.month.amount * 12 - payment.plans.year.amount
                )
              : 1 -
                payment.plans.year.amount / (payment.plans.month.amount * 12)
          }
          amountOff={payment.plans.year.amount_off}
          bestPlan
          last
          selectPlan={() =>
            onRedirectToCheckout(payment.plans.year.id, 'yearly')
          }
        />
      )}
    </Box>
  );
};

export default PlanContainer;
