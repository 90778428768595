import React from 'react';
import { InfoInterface } from './interface';
import useStyles from './style';
import { ACTIVE_COUPON } from '../../../../../../utils/coupon';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const Info = ({}: InfoInterface) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  return (
    <div className={classes.root}>
      {ACTIVE_COUPON === 'ANUAL40' && (
        <>
          <h1
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.ANUAL40.title')
            }}
          />
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.ANUAL40.description')
            }}
          ></p>
        </>
      )}

      {ACTIVE_COUPON === 'NIVER40' && (
        <>
          <h1
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.NIVER40.title')
            }}
          />
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.NIVER40.description')
            }}
          ></p>
        </>
      )}

      {ACTIVE_COUPON === 'NIVER60' && (
        <>
          <h1
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.NIVER60.title')
            }}
          />
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.NIVER60.description')
            }}
          ></p>
        </>
      )}

      {ACTIVE_COUPON === 'LINGUAPT50' && (
        <>
          <div
            className={classes.title}
            style={{ fontSize: '19px', fontWeight: '400' }}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.LINGUAPT50.title')
            }}
          />
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.LINGUAPT50.description')
            }}
          />
        </>
      )}

      {ACTIVE_COUPON === 'WS50' && (
        <>
          <div
            className={classes.title}
            style={{ fontSize: '19px', fontWeight: '400' }}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.WS50.title')
            }}
          />
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: translate('banner.discount.WS50.description')
            }}
          />
        </>
      )}
    </div>
  );
};

export default Info;
