const LANGUAGE_ES: any = {
  configurator: {
    language: {
      title: 'Configuración de idioma',
      options: {
        portuguese: 'Portugués',
        english: 'Inglés',
        spanish: 'Español'
      }
    },
    platform: {
      title: 'Configuraciones de la Plataforma',
      options: {
        standard: 'Estándar',
        education: 'Educación',
        agents: 'Agentes'
      }
    }
  },
  page: {
    profile: {
      title: 'Perfil',
      section: {
        info: {
          description: 'Tus Datos',
          name: '¿Cómo deberíamos llamarte?',
          email: 'Correo electrónico',
          phone: 'Teléfono',
          action: {
            edit: {
              label: 'Editar Datos'
            }
          },
          uninformed: 'No Proporcionado'
        },
        general: {
          description: 'Mi Cuenta',
          firstAccess: 'Primer Acceso',
          texts: 'Textos',
          words: 'Palabras',
          suggestion: {
            title: 'Desviaciones de Estilo',
            options: {
              clarity: 'Claridad',
              conciseness: 'Concisión',
              strength: 'Fuerza',
              originality: 'Originalidad',
              formatting: 'Formato',
              correction: 'Corrección'
            },
            corrected: 'desviaciones corregidas'
          },
          uninformed: 'No Proporcionado',
          tag: {
            free: 'FREE',
            paid: 'PRO'
          }
        }
      }
    },
    editProfile: {
      title: 'Editar Perfil',
      description: 'Configuraciones del perfil de usuario',
      form: {
        name: {
          label: 'Nombre',
          placeholder: 'Ingresa tu nombre...'
        },
        email: {
          label: 'Correo electrónico',
          placeholder: 'Ingresa tu correo electrónico...'
        },
        phone: {
          label: 'Teléfono',
          placeholder: 'Ingresa tu número de teléfono...',
          mask: '(99) 99999-9999'
        }
      },
      action: {
        update: 'Guardar'
      }
    },
    signIn: {
      title: 'Login',
      description:
        'Sus datos están seguros con nosotros, bajo la protección de la LGPD. Acceda a los términos de uso y las políticas de privacidad.',
      leftContainerFlow: {
        description:
          'Sus datos están seguros con nosotros, bajo la protección de la LGPD. Acceda a los términos de uso y las políticas de privacidad.'
      },
      form: {
        title: 'Accede a tu cuenta',
        email: {
          label: 'Correo electrónico',
          placeholder: 'Introduce tu correo electrónico'
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Introduce tu contraseña'
        },
        action: {
          signIn: 'Iniciar sesión',
          signUp: 'Crear una cuenta',
          forgot: '¿Olvidaste tu contraseña?',
          connected: 'Mantenerme conectado',
          firstAccess: '¿Primera vez?',
          signInWithGoogle: 'Iniciar sesión con Google'
        },
        notify: {
          required: {
            email: 'El campo de correo electrónico es obligatorio.',
            password: 'El campo de contraseña es obligatorio.'
          }
        }
      }
    },
    signUp: {
      title: 'Crea tu cuenta',
      description:
        'Tus datos están seguros con nosotros, bajo la protección de la LGPD. Accede a los términos de uso y las políticas de privacidad.',
      leftContainerFlow: {
        description:
          'Tus datos están seguros con nosotros, bajo la protección de la LGPD. Accede a los términos de uso y las políticas de privacidad.'
      },
      form: {
        title: 'Crea tu cuenta',
        name: {
          label: '¿Cómo podemos llamarte?',
          placeholder: 'Ingresa tu nombre'
        },
        email: {
          label: 'Correo electrónico',
          placeholder: 'Ingresa tu correo electrónico'
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Ingresa tu contraseña'
        },
        action: {
          signIn: 'Iniciar sesión',
          signUp: 'Crear cuenta',
          haveAnAccount: '¿Ya tienes una cuenta?',
          signUpWithGoogle: 'Regístrate con Google',
          acceptedTerms: {
            one: 'He leído y acepto los',
            two: 'términos de uso',
            three: ',',
            four: 'política de privacidad',
            five: 'y',
            six: 'política de pago'
          }
        },
        notify: {
          required: {
            name: 'El campo de nombre es obligatorio.',
            email: 'El campo de correo electrónico es obligatorio.',
            password: 'El campo de contraseña es obligatorio.',
            captcha: 'Por favor, completa el captcha.',
            accept: 'Por favor, acepta los términos.'
          }
        }
      }
    },
    forgotPassword: {
      title: 'Olvidé mi contraseña',
      description:
        'Tus datos están seguros con nosotros, bajo la protección de la LGPD. Accede a los términos de uso y las políticas de privacidad.',
      leftContainerFlow: {
        description:
          'Tus datos están seguros con nosotros, bajo la protección de la LGPD. Accede a los términos de uso y las políticas de privacidad.'
      },
      form: {
        title: 'Recupera tu contraseña',
        description:
          'Ingresa tu correo electrónico para recibir instrucciones sobre cómo restablecer tu contraseña.',
        email: {
          label: 'Correo electrónico',
          placeholder: 'Ingresa tu correo electrónico'
        },
        action: {
          sendEmail: 'Enviar correo de recuperación',
          signUp: 'Crear una cuenta',
          firstAccess: '¿Primera vez?'
        },
        notify: {
          required: {
            email: 'El campo de correo electrónico es obligatorio.'
          }
        }
      }
    },
    passwordRecovery: {
      title: 'Nueva Contraseña',
      description:
        'Tus datos están seguros con nosotros, bajo la protección de la LGPD. Accede a los términos de uso y las políticas de privacidad.',
      leftContainerFlow: {
        description:
          'Tus datos están seguros con nosotros, bajo la protección de la LGPD. Accede a los términos de uso y las políticas de privacidad.'
      },
      form: {
        title: '¡Solo un paso más!',
        description: 'Ingresa la nueva contraseña para tu acceso.',
        password: {
          label: 'Nueva contraseña',
          placeholder: 'Ingresa tu nueva contraseña'
        },
        action: {
          updatePassword: 'Actualizar mi contraseña'
        }
      }
    },
    project: {
      list: {
        title: 'Proyectos',
        description: 'Aquí puedes encontrar todos tus proyectos.',
        search: {
          label: 'Buscar',
          placeholder: 'Buscar un proyecto...'
        },
        action: {
          create: 'Crear Proyecto',
          update: 'Editar',
          delete: 'Eliminar'
        },
        notFound: 'No se encontró ningún proyecto...',
        notAdded: 'Aún no se ha agregado ningún proyecto...',
        notTitle: 'Sin título'
      },
      modal: {
        create: {
          title: 'Nuevo Proyecto',
          description: 'Crea nuevos proyectos para organizar tu trabajo.',
          form: {
            name: {
              label: 'Nombre',
              placeholder: 'Ingresa un nombre...'
            },
            description: {
              label: 'Descripción',
              placeholder: 'Ingresa una descripción...'
            }
          },
          action: {
            cancel: 'Cancelar',
            confirm: 'Crear'
          }
        },
        update: {
          title: 'Editar Proyecto',
          description: 'Actualiza el nombre y la descripción de tu proyecto.',
          form: {
            name: {
              label: 'Nombre',
              placeholder: 'Ingresa un nombre...'
            },
            description: {
              label: 'Descripción',
              placeholder: 'Ingresa una descripción...'
            }
          },
          action: {
            cancel: 'Cancelar',
            confirm: 'Guardar'
          }
        },
        delete: {
          title: 'Eliminar Proyecto',
          description: '¿Estás seguro de que deseas eliminar este proyecto?',
          action: {
            cancel: 'Cancelar',
            confirm: 'Eliminar'
          }
        }
      }
    },
    brand: {
      list: {
        title: 'Centro de Marca',
        search: {
          label: 'Buscar',
          placeholder: 'Busca una marca...'
        },
        action: {
          create: 'Crear marca',
          update: 'Editar',
          delete: 'Eliminar'
        },
        notFound: 'No se encontró ninguna marca...',
        notAdded: 'Aún no se ha añadido ninguna marca...',
        loading: 'Cargando...',
        notTitle: 'Sin título'
      },
      create: {
        title: 'Crear nueva marca',
        description: {
          one: '¡Bienvenido a tu viaje de creación de marca! Aquí compartirás las definiciones de comunicación de tu negocio.',
          two: 'Con esta valiosa información, Clarice.ai estará lista para crear una guía única y memorable, alineada con los objetivos y valores de tu marca.'
        },
        form: {
          name: {
            label: 'Nombre',
            placeholder: '¿Cuál es el nombre de tu marca? \nEjemplo: Nike'
          },
          description: {
            label: 'Descripción',
            placeholder:
              'Cuéntanos un poco sobre tu marca... \n\nEjemplo: Nike es una marca global de ropa y calzado deportivo conocida por su innovación y calidad.'
          },
          audience: {
            label: 'Audiencia',
            placeholder:
              '¿Cuál es tu público objetivo? \n\nEjemplo: Atletas, entusiastas del deporte, jóvenes interesados en el fitness.'
          },
          tone: {
            label: 'Tono',
            placeholder:
              'Define el tono de voz de tu marca. \n\nEjemplo: Motivacional e inspirador.'
          },
          personality: {
            label: 'Personalidad',
            placeholder:
              'Describe la personalidad de tu marca. \n\nEjemplo: Una marca audaz, valiente e inspiradora, centrada en los atletas y en aquellos que buscan superar sus límites.'
          },
          missionVisionAndValue: {
            label: 'Misión, Visión y Valores',
            placeholder:
              'Introduce la misión, visión y valores de tu marca. \n\nMisión: Inspirar e innovar para todos los atletas del mundo. \nVisión: Llevar inspiración e innovación a todos los atletas. \nValores: Determinación, innovación, trabajo en equipo.'
          },
          keywords: {
            label: 'Palabras clave',
            placeholder:
              'Introduce palabras clave relacionadas con tu marca. \n\nEjemplo: Innovación, rendimiento, superación.'
          },
          preferredWordsAndPhrases: {
            label: 'Palabras y frases preferidas',
            placeholder:
              'Enumera las palabras y frases que prefieres usar en tu comunicación. \n\nEjemplo: Just Do It, excelencia, campeón.'
          },
          wordsAndPhrasesToAvoid: {
            label: 'Palabras y frases a evitar',
            placeholder:
              'Enumera las palabras y frases que se deben evitar. \n\nEjemplo: Fracaso, rendirse, imposible.'
          },
          image: {
            label: 'Sube una imagen del logotipo de tu marca',
            placeholder: 'Sube una imagen del logotipo de tu marca',
            chosen: 'Seleccionado:',
            chooseImage: 'Seleccionar imagen'
          }
        },
        action: {
          generateWithIA: {
            label: 'Generar con IA',
            tooltip:
              '¡Usa nuestra Inteligencia Artificial y describe tu marca en segundos!'
          },
          cancel: 'Volver',
          confirm: 'Guardar'
        }
      },
      update: {
        title: 'Editar Marca',
        form: {
          name: {
            label: 'Nombre',
            placeholder: '¿Cuál es el nombre de tu marca? \nEjemplo: Nike'
          },
          description: {
            label: 'Descripción',
            placeholder:
              'Cuéntanos un poco sobre tu marca... \n\nEjemplo: Nike es una marca global de ropa y calzado deportivo conocida por su innovación y calidad.'
          },
          audience: {
            label: 'Audiencia',
            placeholder:
              '¿Quién es tu público objetivo? \n\nEjemplo: Atletas, entusiastas del deporte, jóvenes interesados en fitness.'
          },
          tone: {
            label: 'Tono',
            placeholder:
              'Define el tono de voz de tu marca. \n\nEjemplo: Motivacional e inspirador.'
          },
          personality: {
            label: 'Personalidad',
            placeholder:
              'Describe la personalidad de tu marca. \n\nEjemplo: Una marca audaz, valiente e inspiradora, enfocada en atletas y personas que buscan superar sus límites.'
          },
          missionVisionAndValue: {
            label: 'Misión, Visión y Valores',
            placeholder:
              'Introduce la misión, visión y valores de tu marca. \n\nMisión: Inspirar e innovar para todos los atletas del mundo. \nVisión: Llevar inspiración e innovación a todos los atletas. \nValores: Determinación, innovación, trabajo en equipo.'
          },
          keywords: {
            label: 'Palabras clave',
            placeholder:
              'Introduce palabras clave relacionadas con tu marca. \n\nEjemplo: Innovación, rendimiento, superación.'
          },
          preferredWordsAndPhrases: {
            label: 'Palabras y Frases Preferidas',
            placeholder:
              'Enumera las palabras y frases que prefieres usar en tu comunicación. \n\nEjemplo: Just Do It, excelencia, campeón.'
          },
          wordsAndPhrasesToAvoid: {
            label: 'Palabras y Frases a Evitar',
            placeholder:
              'Enumera las palabras y frases que deben evitarse. \n\nEjemplo: Fracaso, rendirse, imposible.'
          },
          image: {
            label: 'Sube la imagen del logotipo de tu marca',
            placeholder: 'Sube la imagen del logotipo de tu marca',
            chosen: 'Seleccionado:',
            chooseImage: 'Seleccionar imagen'
          }
        },
        action: {
          cancel: 'Volver',
          confirm: 'Guardar'
        }
      },
      generateWithIA: {
        title: 'Completa el campo a continuación y mira la magia suceder',
        description:
          'La inteligencia artificial de Clarice.ai está lista para crear una marca increíble para ti.',
        form: {
          description: {
            label: 'Descripción de la marca',
            placeholder:
              'Describe las principales características de tu marca...'
          }
        },
        action: {
          generate: 'Generar con IA'
        }
      },
      modal: {
        delete: {
          title: 'Eliminar Marca',
          description: '¿Estás seguro de que deseas eliminar esta marca?',
          action: {
            cancel: 'Cancelar',
            confirm: 'Eliminar'
          }
        }
      }
    },
    business: {
      title: 'Mi Negocio',
      form: {
        name: {
          label: 'Nombre',
          placeholder: 'Ingresa el nombre de tu negocio...'
        }
      },
      action: {
        create: 'Registrar',
        update: 'Guardar'
      },
      notPermission: 'No tienes permiso para acceder a esta página...',
      list: {
        title: 'Usuarios',
        action: {
          add: 'Agregar Usuario',
          delete: 'Eliminar'
        },
        name: 'Nombre',
        email: 'Correo Electrónico',
        lastAccess: 'Último Acceso',
        notAdded: 'No se han agregado usuarios hasta el momento...',
        badge: {
          owner: 'Propietario',
          active: 'Activo',
          pending: 'Pendiente'
        },
        licenses: {
          of: 'de',
          used: 'licencias utilizadas'
        }
      },
      modal: {
        add: {
          title: 'Agregar Usuario',
          description: '¡Agrega un usuario a tu negocio ahora mismo!',
          form: {
            email: {
              label: 'Ingresa el correo electrónico del usuario'
            }
          },
          action: {
            cancel: 'Cancelar',
            confirm: 'Enviar Invitación'
          }
        }
      }
    },
    backoffice: {
      title: 'Backoffice',
      notPermission: 'No tienes permiso para acceder a esta página...',
      business: {
        dashboard: {
          badge: {
            total: 'Total',
            active: 'Activos',
            expired: 'Expirados'
          }
        },
        list: {
          title: 'Empresas',
          action: {
            create: 'Añadir',
            update: 'Editar',
            delete: 'Eliminar'
          },
          company: 'Empresa',
          plan: 'Plan',
          startDate: 'Fecha de Inicio',
          endDate: 'Fecha Final',
          notAdded: 'Aún no se ha añadido ninguna empresa...',
          loading: 'Cargando...',
          badge: {
            total: 'Total',
            active: 'Activo',
            expired: 'Expirado',
            pending: 'Pendiente'
          },
          licenses: {
            of: 'de',
            used: 'licencias utilizadas'
          }
        },
        modal: {
          add: {
            title: 'Añadir Empresa',
            description:
              '¡Crea una empresa y asígnala a un usuario ahora mismo!',
            form: {
              name: {
                label: 'Nombre de la empresa',
                placeholder: ''
              },
              email: {
                label: 'Correo electrónico del propietario',
                placeholder: ''
              },
              licenses: {
                label: 'Cantidad de licencias',
                placeholder: ''
              },
              startDate: {
                label: 'Fecha de Inicio',
                placeholder: ''
              },
              endDate: {
                label: 'Fecha Final',
                placeholder: ''
              },
              sharedBrand: {
                label: 'Manual de Marca',
                placeholder: ''
              }
            },
            action: {
              cancel: 'Cancelar',
              confirm: 'Crear'
            }
          }
        },
        update: {
          title: 'Editar Empresa',
          form: {
            name: {
              label: 'Nombre de la empresa',
              placeholder: ''
            },
            licenses: {
              label: 'Cantidad de licencias',
              placeholder: ''
            },
            startDate: {
              label: 'Fecha de Inicio',
              placeholder: ''
            },
            endDate: {
              label: 'Fecha Final',
              placeholder: ''
            },
            sharedBrand: {
              label: 'Manual de Marca',
              placeholder: ''
            }
          },
          action: {
            confirm: 'Guardar'
          },
          user: {
            list: {
              title: 'Usuarios',
              action: {
                add: 'Añadir Usuario',
                delete: 'Eliminar'
              },
              name: 'Nombre',
              email: 'Correo Electrónico',
              lastAccess: 'Último Acceso',
              notAdded: 'Aún no se ha añadido ningún usuario...',
              badge: {
                owner: 'Propietario',
                active: 'Activo',
                pending: 'Pendiente'
              },
              licenses: {
                of: 'de',
                used: 'licencias utilizadas'
              }
            },
            modal: {
              add: {
                title: 'Añadir Usuario',
                description: '¡Añade un usuario al negocio ahora mismo!',
                form: {
                  email: {
                    label: 'Introduce el correo electrónico del usuario'
                  }
                },
                action: {
                  cancel: 'Cancelar',
                  confirm: 'Guardar'
                }
              }
            }
          }
        }
      }
    },
    credits: {
      title: 'Créditos y Facturación',
      available: 'Créditos Disponibles',
      total: 'Total de Créditos',
      used: 'Créditos Utilizados',
      plan: 'Créditos del Plan',
      bonus: 'Créditos de Bonificación',
      percentage: {
        standard: '% de créditos utilizados',
        plan: '% de créditos del plan utilizados',
        bonus: '% de créditos de bonificación utilizados'
      },
      signature: {
        title: 'Plan',
        badge: {
          free: 'GRATIS',
          paid: 'PRO',
          active: 'Activo',
          expired: 'Expirado'
        },
        active: {
          description:
            'Mejora tu cuenta para acceder a más créditos de bonificación.'
        },
        canceled: {
          one: 'Después de este período, ya no tendrás acceso a los beneficios PRO.',
          two: 'Primera',
          three: 'Próxima',
          four: 'cobranza'
        },
        trial: {
          one: 'Tu período de prueba termina en',
          two: 'días y',
          three: 'horas.',
          four: 'La próxima renovación es en',
          five: 'días'
        },
        action: {
          cancel: 'Cancelar Suscripción',
          get: 'Obtener Plan',
          manage: 'Gestionar Suscripción'
        }
      }
    },
    templates: {
      title: 'Plantillas',
      options: {
        all: 'Todos',
        ads: 'Anuncios',
        blog: 'Blog',
        ecommerce: 'Comercio Electrónico',
        email: 'Correo Electrónico',
        frameworks: 'Marcos de Trabajo',
        google: 'Google',
        marketing: 'Marketing',
        seo: 'SEO',
        socialMedia: 'Redes Sociales',
        videos: 'Videos',
        website: 'Sitios Web',
        tools: 'Herramientas',
        others: 'Otros'
      },
      list: {
        contentSummarizer: {
          title: 'Resumidor de Contenidos',
          description: 'Obtén un resumen con los puntos clave de un texto.',
          fields: {
            content: {
              label: 'Contenido',
              placeholder: 'Introduce el contenido para resumir...'
            },
            count: {
              label: 'Tamaño',
              placeholder: '¿Cuántas palabras deseas resumir?',
              options: {
                '50': '50 palabras',
                '100': '100 palabras',
                '200': '200 palabras',
                '300': '300 palabras',
                '400': '400 palabras'
              }
            }
          }
        },
        facebookAdsDescription: {
          title: 'Anuncio para Facebook',
          description:
            'Crea anuncios para Facebook que inciten a tus clientes a hacer clic y comprar.',
          fields: {
            name: {
              label: 'Nombre de la Empresa o Producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuéntanos sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        subtitleInstagram: {
          title: 'Subtítulo para Instagram',
          description:
            'Escribe subtítulos atractivos para tus publicaciones en Instagram.',
          fields: {
            description: {
              label: '¿De qué trata tu publicación?',
              placeholder: 'Ejemplo: Línea de alimentos para mascotas'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        blogPostIntro: {
          title: 'Introducción para Blog',
          description:
            'Supera el bloqueo de la página en blanco con una introducción para tu blog.',
          fields: {
            title: {
              label: 'Título',
              placeholder:
                'Ejemplo: Cinco nuevos modelos de autos eléctricos que se lanzarán el próximo año.'
            },
            audience: {
              label: 'Audiencia',
              placeholder:
                'Ejemplo: Personas interesadas en vehículos ecológicos'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        blogPostConclusion: {
          title: 'Conclusión de Blog',
          description:
            'Finaliza tus publicaciones de blog con una conclusión atractiva.',
          fields: {
            title: {
              label: 'Título',
              placeholder:
                'Ejemplo: Cinco nuevos modelos de autos eléctricos que se lanzarán el próximo año.'
            },
            audience: {
              label: 'Audiencia',
              placeholder:
                'Ejemplo: Personas interesadas en vehículos ecológicos'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        blogPostOutline: {
          title: 'Esquema para Blog',
          description:
            'Organiza tus ideas y conceptos en listas detalladas antes de escribir.',
          fields: {
            title: {
              label: 'Título',
              placeholder:
                'Ejemplo: Cinco nuevos modelos de autos eléctricos que se lanzarán el próximo año.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        blogPostTopicIdeas: {
          title: 'Ideas de Tópicos para Blog',
          description:
            'Genera nuevas ideas de temas para blogs que capten la atención de los lectores.',
          fields: {
            name: {
              label: 'Nombre de la empresa/producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Descripción de la empresa/producto',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: 'Audiencia',
              placeholder: 'Ejemplo: Profesionales de marketing'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        commands: {
          title: 'Comandos',
          description:
            'Dile a Clarice.ai exactamente lo que quieres que escriba usando comandos.',
          fields: {
            description: {
              label: 'Introduce tu comando para Clarice.ai',
              placeholder:
                'Ejemplo: Narra una historia sobre Max viajando a la luna al estilo de Morgan Freeman.'
            },
            content: {
              label: '¿Tienes alguna información adicional?',
              placeholder:
                'Ejemplo: Max era un gato curioso al que le gustaban las aventuras.'
            }
          }
        },
        companyBio: {
          title: 'Biografía de la Empresa',
          description:
            'Describe de forma atractiva la trayectoria de tu empresa.',
          fields: {
            name: {
              label: 'Nombre de la empresa',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Información de la empresa',
              placeholder: 'Ejemplo: ¿Sobre qué trata tu empresa?'
            },
            audience: {
              label: 'Audiencia',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        contentImprover: {
          title: 'Mejorador de Contenido',
          description: 'Reescribe tu contenido para hacerlo más interesante.',
          fields: {
            content: {
              label: 'Contenido',
              placeholder:
                'Ejemplo: Presentamos soluciones innovadoras que simplifican las operaciones empresariales...'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        rewriteText: {
          title: 'Reescribir Texto',
          description:
            'Reescribe frases y párrafos sin alterar el significado original.',
          fields: {
            content: {
              label: 'Contenido',
              placeholder:
                'Ejemplo: Presentamos soluciones innovadoras que simplifican las operaciones empresariales...'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        emailSubject: {
          title: 'Asuntos de Correo Electrónico',
          description: 'Escribe asuntos más atractivos para tu correo.',
          fields: {
            name: {
              label: 'Nombre de la empresa/producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: '¿De qué trata tu correo?',
              placeholder:
                'Ejemplo: Presentamos nuestro nuevo software que utiliza IA...'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Inteligente'
            }
          }
        },
        emailMarketing: {
          title: 'Marketing por Correo Electrónico',
          description:
            'Desarrolla campañas de correo electrónico que conviertan y fidelicen a los clientes.',
          fields: {
            name: {
              label: 'Asunto y Preheader',
              placeholder: 'Introduce hasta 50 caracteres para cada uno...'
            },
            description: {
              label: 'Texto del Encabezado (Hero)',
              placeholder: 'Introduce hasta 70 caracteres...'
            },
            content: {
              label: 'Introducción (Después del “Hola, [NOMBRE]!”)',
              placeholder: 'Introduce hasta 3 líneas de texto...'
            },
            cta: {
              label: 'Botón de Llamada a la Acción (CTA)',
              placeholder:
                'Introduce hasta cuatro palabras, idealmente dos o tres...'
            }
          }
        },
        paragraphsGenerator: {
          title: 'Generador de Párrafos',
          description:
            'Permite que Clarice.ai elabore párrafos impresionantes que cautiven a tus lectores.',
          fields: {
            description: {
              label: '¿Sobre qué es tu párrafo?',
              placeholder:
                'Ejemplo: ¿Cuáles son las mejores frutas de cada temporada?'
            },
            keywords: {
              label: 'Palabras clave',
              placeholder: 'Ejemplo: Manzana, naranja'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Informativo'
            }
          }
        },
        productDescription: {
          title: 'Descripción de Producto',
          description:
            'Clarice.ai puede crear descripciones más elaboradas para tus productos.',
          fields: {
            name: {
              label: 'Nombre del producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuéntame sobre tu producto',
              placeholder:
                'Ejemplo: Plataforma de corrección de texto inteligente.'
            },
            audience: {
              label: 'Audiencia',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        subtitleTikTok: {
          title: 'Subtítulo para TikTok',
          description:
            'Escribe subtítulos atractivos para tus videos en TikTok.',
          fields: {
            description: {
              label: '¿De qué trata tu video?',
              placeholder:
                'Ejemplo: Cómo crear el itinerario de viaje perfecto.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        carouselInstagram: {
          title: 'Carrusel para Instagram',
          description:
            'Crea contenido diapositiva por diapositiva para un carrusel en Instagram.',
          fields: {
            description: {
              label: 'Tema principal',
              placeholder: 'Ejemplo: Cómo criar conejos.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        titleYouTube: {
          title: 'Título para video de YouTube',
          description:
            'Crea títulos optimizados y atractivos para tus videos en YouTube.',
          fields: {
            description: {
              label: '¿De qué trata tu video?',
              placeholder: 'Ejemplo: Los animales más fantásticos del planeta.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        tagsYouTube: {
          title: 'Etiquetas para video de YouTube',
          description:
            'Genera etiquetas para tus videos a partir de palabras clave.',
          fields: {
            title: {
              label: 'Tema o título del video',
              placeholder: 'Ejemplo: Cómo generar ingresos extra...'
            }
          }
        },
        youtubeTopicIdeas: {
          title: 'Ideas de temas para video de YouTube',
          description:
            'Encuentra temas interesantes para tus videos en YouTube.',
          fields: {
            description: {
              label: '¿De qué trata tu video?',
              placeholder:
                'Ejemplo: Los misterios de los elefantes en Tailandia.'
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: ¿Cuál es tu público objetivo?'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        descriptionYouTube: {
          title: 'Descripción para video de YouTube',
          description:
            'Escribe descripciones atractivas para tus videos en YouTube.',
          fields: {
            title: {
              label: 'Título del video',
              placeholder:
                'Ejemplo: Recetas deliciosas para principiantes en la cocina.'
            }
          }
        },
        youtubeOutline: {
          title: 'Esquema de guion para video de YouTube',
          description:
            'Crea esquemas de guion para videos de tipo "Lista" o "Cómo hacer".',
          fields: {
            title: {
              label: 'Título o tema del video',
              placeholder: 'Ejemplo: Las 10 mayores leyendas del fútbol...'
            }
          }
        },
        youtubeIntroduction: {
          title: 'Introducción para video de YouTube',
          description:
            'Crea una introducción atractiva para captar la atención de los espectadores.',
          fields: {
            title: {
              label: 'Tema o título del video',
              placeholder:
                'Ejemplo: Las mejores jugadas de tiro libre en la historia del fútbol.'
            }
          }
        },
        weeklyContentCalendar: {
          title: 'Calendario semanal de contenido',
          description:
            'Genera un calendario semanal de contenido basado en palabras clave o temas principales.',
          fields: {
            keywords: {
              label: 'Palabras clave o tema principal',
              placeholder: 'Ejemplo: Las tecnologías del futuro.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        commemorativeDate: {
          title: 'Fechas conmemorativas',
          description:
            'Crea mensajes personalizados para felicitaciones y fechas conmemorativas.',
          fields: {
            description: {
              label: 'Motivo de la celebración',
              placeholder: 'Ejemplo: Día de las madres.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Creativo'
            }
          }
        },
        explainToAChild: {
          title: 'Explica a un niño',
          description:
            'Reescribe el texto para hacerlo más fácil de entender por los niños.',
          fields: {
            description: {
              label: '¿Qué quieres explicar?',
              placeholder: 'Ejemplo: ¿Qué es el espacio exterior?'
            },
            count: {
              label: 'Grado escolar',
              placeholder: 'Selecciona el grado escolar',
              options: {
                '1': '1º año',
                '2': '2º año',
                '3': '3º año',
                '5': '5º año',
                '7': '7º año',
                '9': '9º año'
              }
            }
          }
        },
        domainNameGenerator: {
          title: 'Generador de nombres de dominio',
          description: 'Genera ideas de nombres de dominio para tu nicho.',
          fields: {
            description: {
              label: '¿Cuál es tu nicho?',
              placeholder: 'Ejemplo: Empresa de tecnología'
            }
          }
        },
        citation: {
          title: 'Citas inspiradoras',
          description: 'Obtén citas inspiradoras de grandes personalidades.',
          fields: {
            description: {
              label: 'Tema',
              placeholder: 'Ejemplo: El valor de la amistad...'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        companySlogan: {
          title: 'Eslogan para empresas',
          description: 'La frase perfecta que representa y destaca tu negocio.',
          fields: {
            name: {
              label: 'Nombre de la empresa',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Descripción de la empresa',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        growthIdeas: {
          title: 'Ideas de crecimiento',
          description:
            'Tácticas de crecimiento para ayudar a tu empresa a expandirse.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        companyMission: {
          title: 'Misión de la empresa',
          description:
            'Una declaración clara de los objetivos y propósitos de tu empresa.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre la empresa',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            }
          }
        },
        companyName: {
          title: 'Nombre para empresa o producto',
          description: 'Genera un nombre único para tu negocio o producto.',
          fields: {
            description: {
              label: 'Cuenta más sobre tu negocio o producto',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            keywords: {
              label: 'Palabras clave',
              placeholder: 'Ejemplo: Corrector, Inteligencia Artificial'
            }
          }
        },
        companyVision: {
          title: 'Visión de la empresa',
          description:
            'Una visión clara que atraiga a los clientes y empleados adecuados.',
          fields: {
            name: {
              label: 'Nombre de la empresa',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Descripción de la empresa',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        facebookAdsTitle: {
          title: 'Título para Facebook Ads',
          description:
            'Crea títulos de alto rendimiento para tus anuncios en Facebook.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        seoHomepage: {
          title: 'Página principal (SEO)',
          description:
            'Escribe títulos optimizados y meta descripciones para tu página principal.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Descripción del producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            keywords: {
              label: 'Palabras clave',
              placeholder: 'Ejemplo: Corrector, Inteligencia Artificial'
            }
          }
        },
        seoServicePage: {
          title: 'Página de servicio (SEO)',
          description:
            'Escribe títulos optimizados y meta descripciones para tu página de servicios.',
          fields: {
            name: {
              label: 'Nombre de la empresa',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            theme: {
              label: 'Nombre del servicio',
              placeholder: 'Ejemplo: Clarice.ai Escritora'
            },
            description: {
              label: 'Descripción del servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            keywords: {
              label: 'Palabras clave',
              placeholder: 'Ejemplo: Corrector, Inteligencia Artificial'
            }
          }
        },
        seoProductPage: {
          title: 'Página de producto (SEO)',
          description:
            'Escribe títulos optimizados y meta descripciones para tus páginas de productos.',
          fields: {
            name: {
              label: 'Nombre de la empresa',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            theme: {
              label: 'Nombre del producto',
              placeholder: 'Ejemplo: Clarice.ai Escritora'
            },
            description: {
              label: 'Descripción del producto',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            keywords: {
              label: 'Palabras clave',
              placeholder: 'Ejemplo: Corrector, Inteligencia Artificial'
            }
          }
        },
        seoBlogPost: {
          title: 'Publicación de blog (SEO)',
          description:
            'Escribe títulos optimizados y meta descripciones para tus publicaciones de blog.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            title: {
              label: 'Título de la publicación',
              placeholder: 'Ejemplo: ¿Qué es Clarice.ai?'
            },
            description: {
              label: 'Descripción de la publicación',
              placeholder:
                'Ejemplo: Clarice.ai es un corrector de texto inteligente...'
            }
          }
        },
        linkedinAdsTitle: {
          title: 'Título para LinkedIn Ads',
          description:
            'Crea títulos profesionales para tus anuncios en LinkedIn.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        linkedinAdsDescription: {
          title: 'Descripción para LinkedIn Ads',
          description:
            'Crea descripciones atractivas para tus anuncios en LinkedIn.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        linkedinPost: {
          title: 'Publicación en LinkedIn',
          description:
            'Crea publicaciones inspiradoras y atractivas en LinkedIn.',
          fields: {
            description: {
              label: '¿De qué trata tu publicación?',
              placeholder: 'Ejemplo: ¿Cómo la IA impactará las profesiones?'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            cta: {
              label: 'Acción deseada',
              placeholder: 'Ejemplo: Etiqueta a un amigo en la publicación'
            }
          }
        },
        twitterPost: {
          title: 'Publicación para Twitter',
          description:
            'Genera tweets creativos y atractivos para aumentar tu presencia en Twitter.',
          fields: {
            description: {
              label: '¿De qué trata tu publicación?',
              placeholder:
                'Ejemplo: Nueva galaxia en formación descubierta por telescopio espacial...'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        googleAdsTitle: {
          title: 'Título para Google Ads',
          description:
            'Crea títulos de alta conversión para tus anuncios en Google.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        googleAdsDescription: {
          title: 'Descripción para Google Ads',
          description:
            'Crea descripciones de alta conversión para tus anuncios en Google.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        resourcesForBenefits: {
          title: 'Recursos a Beneficios',
          description:
            'Convierte las características de tu producto en beneficios que motivan la acción.',
          fields: {
            description: {
              label: 'Descripción del producto',
              placeholder:
                'Ejemplo: Simplificamos la automatización de tareas para agencias...'
            }
          }
        },
        imagePrompt: {
          title: 'Prompt para Imágenes',
          description:
            'Crea prompts para imágenes que podrás utilizar con Clarice.ai Arte.',
          fields: {
            description: {
              label: 'Idea general o tema',
              placeholder: 'Ejemplo: Un gato con botas amarillas'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        personalBiography: {
          title: 'Biografía Personal',
          description:
            'Crea una biografía que destaque tu experiencia y llame la atención de tus clientes.',
          fields: {
            name: {
              label: '¿Cuál es tu nombre?',
              placeholder: 'Ejemplo: Felipe'
            },
            description: {
              label: 'Información personal',
              placeholder:
                'Ejemplo: Detalles sobre tu familia, educación y trabajos relevantes.'
            },
            pointsOfView: {
              label: 'Punto de Vista',
              placeholder: 'Ejemplo: Primera o Tercera Persona'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        appNotifications: {
          title: 'Notificaciones de Aplicaciones y SMS',
          description:
            'Crea notificaciones que atraigan a tus usuarios y los hagan volver a usar tu app o producto.',
          fields: {
            description: {
              label: 'Describe la notificación',
              placeholder:
                'Ejemplo: Recordatorio para usar las funciones de nuestra aplicación.'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        pushNotification: {
          title: 'Notificación Push',
          description:
            'Crea notificaciones atractivas para mantener a tus usuarios comprometidos con tu producto.',
          fields: {
            title: {
              label: 'Título',
              placeholder: 'Escribe un título de hasta 30 caracteres...'
            },
            description: {
              label: 'Mensaje',
              placeholder: 'Escribe un mensaje de hasta 90 caracteres...'
            }
          }
        },
        aidaStructure: {
          title: 'Estructura AIDA',
          description:
            'Usa el framework de marketing AIDA: Atención, Interés, Deseo, Acción.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Descripción del producto',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        pasStructure: {
          title: 'Estructura PAS',
          description:
            'Un framework poderoso para la creación de copywriting eficaz.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Descripción del producto',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        faqGenerator: {
          title: 'Generador de Preguntas Frecuentes',
          description:
            'Crea preguntas frecuentes para tus publicaciones y páginas.',
          fields: {
            description: {
              label: 'Tema principal',
              placeholder: 'Ejemplo: Curso de programación para principiantes'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        engagingQuestions: {
          title: 'Preguntas Envolventes',
          description:
            'Crea preguntas creativas que aumentan la participación de tu público.',
          fields: {
            description: {
              label: 'Tema',
              placeholder:
                'Ejemplo: Crecimiento de la adopción de criptomonedas'
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Inversionistas'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        jobInterview: {
          title: 'Entrevista de Trabajo',
          description:
            'Deja que Clarice.ai cree preguntas para tu entrevista de trabajo.',
          fields: {
            description: {
              label: '¿Cuál es el puesto de trabajo?',
              placeholder: 'Ejemplo: Desarrollador'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        classPlan: {
          title: 'Plan de Clase',
          description:
            'Crea un plan de clase eficaz con una estrategia clara de enseñanza.',
          fields: {
            content: {
              label: 'Contenido',
              placeholder: 'Ejemplo: Curso de mantenimiento de aviones'
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Mecánicos profesionales'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        perfectTitle: {
          title: 'Título Perfecto',
          description:
            'Crea títulos de alta conversión con técnicas de redactores expertos.',
          fields: {
            theme: {
              label: 'Tema',
              placeholder: 'Ejemplo: Inteligencia Artificial'
            },
            description: {
              label: 'Asunto o descripción del producto',
              placeholder:
                'Ejemplo: Cómo la inteligencia artificial optimiza tu tiempo.'
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Escritores'
            }
          }
        },
        customEmails: {
          title: 'Emails Personalizados',
          description:
            'Escribe cold emails personalizados que aumentan tus tasas de respuesta.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu empresa o producto',
              placeholder:
                'Ejemplo: Clarice.ai es un corrector de texto inteligente'
            },
            content: {
              label: '¿Cuál es el contexto u objetivo del email?',
              placeholder:
                'Ejemplo: Programar una demostración de la plataforma'
            },
            cta: {
              label: 'Acción deseada',
              placeholder:
                'Ejemplo: Qué esperas que haga el destinatario después de leer el email'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Inteligente'
            }
          }
        },
        googleMyBusiness: {
          title: 'Google Mi Negocio',
          description:
            'Maximiza tu visibilidad local con un perfil optimizado en Google.',
          fields: {
            location: {
              label: '¿Dónde está ubicada?',
              placeholder: 'Ejemplo: São Paulo'
            },
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: '¿Qué vendes/ofreces?',
              placeholder: 'Ejemplo: Plataforma de corrección de texto'
            }
          }
        },
        listCreator: {
          title: 'Creador de Listas',
          description: 'Genera listas numeradas atractivas basadas en un tema.',
          fields: {
            theme: {
              label: 'Tema',
              placeholder: 'Ejemplo: Tipos de bebidas más caras del mundo'
            },
            count: {
              label: 'Cantidad de elementos',
              placeholder: '¿Cuántos elementos deseas generar?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Inteligente'
            }
          }
        },
        analogyCreator: {
          title: 'Creador de Analogías',
          description:
            'Crea analogías únicas que hacen que tu mensaje sea memorable.',
          fields: {
            theme: {
              label: '¿Sobre qué es la analogía?',
              placeholder: 'Ejemplo: Los animales y las casas'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Inteligente'
            }
          }
        },
        contentExpander: {
          title: 'Expansor de Contenidos',
          description:
            'Escribe la primera oración y Clarice.ai continuará escribiendo por ti.',
          fields: {
            description: {
              label: 'Contenido para expandir',
              placeholder:
                'Ejemplo: La escritura es una de las herramientas más poderosas...'
            },
            count: {
              label: 'Longitud',
              placeholder: '¿Cuántas palabras deseas expandir?',
              options: {
                '50': '50 palabras',
                '100': '100 palabras',
                '200': '200 palabras',
                '300': '300 palabras',
                '400': '400 palabras'
              }
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Inteligente'
            }
          }
        },
        smartAnswers: {
          title: 'Respuestas Inteligentes',
          description:
            'Responde preguntas difíciles de manera clara e inteligente.',
          fields: {
            description: {
              label: 'Tema o Pregunta',
              placeholder: 'Ejemplo: ¿Cómo crear un servidor eficiente?'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Profesional'
            }
          }
        },
        creativeStory: {
          title: 'Historia Creativa',
          description:
            'Desarrolla historias creativas que encanten a tus lectores.',
          fields: {
            theme: {
              label: 'Trama o tema',
              placeholder: 'Ejemplo: Max era un gato curioso y valiente...'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Inteligente'
            }
          }
        },
        uniqueValuePropositions: {
          title: 'Propuesta de Valor Única',
          description:
            'Crea una declaración poderosa que destaque los beneficios de tu oferta.',
          fields: {
            theme: {
              label: 'Descripción del negocio',
              placeholder:
                'Ejemplo: Clarice.ai ayuda a simplificar la creación de contenido.'
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Escritores'
            },
            description: {
              label: 'Problema que resuelve',
              placeholder:
                'Ejemplo: Facilita la escritura profesional para pequeñas empresas.'
            },
            content: {
              label: 'Solución ofrecida',
              placeholder: 'Ejemplo: Creación de contenido eficiente y rápida.'
            }
          }
        },
        linkedinTopicIdeas: {
          title: 'Ideas de Temas para Publicación en LinkedIn',
          description:
            'Encuentra temas interesantes para tus publicaciones en LinkedIn.',
          fields: {
            description: {
              label: '¿Sobre qué es tu publicación?',
              placeholder:
                'Ejemplo: ¿Cómo la IA está cambiando el mercado laboral?'
            },
            count: {
              label: 'Cantidad de ideas',
              placeholder: '¿Cuántas ideas deseas generar?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Profesionales de marketing'
            },
            cta: {
              label: 'Acción deseada',
              placeholder: 'Ejemplo: Etiquetar a un colega en la publicación'
            }
          }
        },
        instagramTopicIdeas: {
          title: 'Ideas de Reels para Instagram',
          description:
            'Descubre temas interesantes para crear reels atractivos en Instagram.',
          fields: {
            description: {
              label: '¿Cuáles son los valores de tu marca?',
              placeholder: 'Ejemplo: Innovación, Creatividad'
            },
            count: {
              label: 'Cantidad de ideas',
              placeholder: '¿Cuántas ideas deseas generar?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Jóvenes emprendedores'
            },
            cta: {
              label: 'Acción deseada',
              placeholder: 'Ejemplo: Etiquetar a un amigo en la publicación'
            }
          }
        },
        instagramAdsDescription: {
          title: 'Anuncio para Instagram',
          description:
            'Crea anuncios atractivos que aumenten las conversiones en Instagram.',
          fields: {
            theme: {
              label: 'Tema',
              placeholder: 'Ejemplo: Inteligencia Artificial'
            },
            description: {
              label: 'Asunto o descripción del producto',
              placeholder:
                'Ejemplo: Cómo la IA puede ayudar a crear mejores contenidos.'
            },
            audience: {
              label: 'Público objetivo',
              placeholder: 'Ejemplo: Profesionales de marketing'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        adsIdeas: {
          title: 'Ideas de Anuncios',
          description:
            'Obtén sugerencias de anuncios para tu marca, producto o servicio.',
          fields: {
            name: {
              label: 'Nombre de la empresa o producto',
              placeholder: 'Ejemplo: Clarice.ai'
            },
            description: {
              label: 'Cuenta sobre tu producto o servicio',
              placeholder: 'Ejemplo: Corrector de texto inteligente'
            },
            audience: {
              label: '¿Cuál es tu público objetivo?',
              placeholder: 'Ejemplo: Escritores'
            },
            tone: {
              label: 'Tono de Voz',
              placeholder: 'Ejemplo: Casual y creativo'
            }
          }
        },
        adsShortly: {
          title: 'Anuncios próximamente',
          description: 'Pronto podrás crear anuncios más atractivos.',
          badge: {
            shortly: 'Próximamente'
          }
        }
      },
      badge: {
        shortly: 'Próximamente'
      },
      action: {
        favorite: 'Marcar como favorito',
        unfavorite: 'Desmarcar como favorito',
        clean: 'Limpiar',
        generate: 'Generar',
        generateNewOutputs: {
          tooltip: 'Generar Nuevas Salidas'
        },
        viewHistory: {
          tooltip: 'Ver Historial'
        },
        clear: {
          tooltip: 'Limpiar Salidas'
        },
        copy: {
          tooltip: 'Copiar Mensaje'
        },
        review: 'Revisar'
      },
      language: {
        title: 'Opciones de Idioma',
        input: {
          label: 'Idioma de Entrada',
          options: {
            portuguese: 'Portugués',
            english: 'Inglés',
            spanish: 'Español'
          }
        },
        output: {
          label: 'Idioma de Salida',
          options: {
            portuguese: 'Portugués',
            english: 'Inglés',
            spanish: 'Español'
          }
        }
      },
      formality: {
        label: 'Formalidad',
        options: {
          default: 'Predeterminado',
          lessFormal: 'Menos Formal',
          moreFormal: 'Más Formal'
        }
      },
      quantity: {
        label: 'Cantidad',
        placeholder: 'Cantidad'
      },
      output: {
        title: 'Rellena los campos y observa cómo ocurre la magia',
        description: {
          one: 'Para obtener los mejores resultados',
          two: 'prueba diferentes entradas, de distintos tamaños.'
        }
      },
      historicNotFound: 'No se encontró historial...'
    },
    chat: {
      title: 'Chat',
      description: {
        one: 'Elige un prompt abajo o escribe el tuyo para empezar a conversar con <strong>Clarice.ai</strong>.',
        two: 'Usa este chat para hablar con <strong>Clarice.ai</strong> y pedir consejos de escritura. Para hablar con el soporte, utiliza el otro chat.'
      },
      form: {
        message: {
          label: 'Escribe un mensaje',
          placeholder: 'Escribe un mensaje...'
        }
      },
      action: {
        submit: 'Enviar',
        clean: 'Limpiar',
        like: {
          tooltip: '¡Buena respuesta!'
        },
        dislike: {
          tooltip: '¡Mala respuesta!'
        },
        refresh: {
          tooltip: 'Intentar de nuevo'
        },
        copy: {
          tooltip: 'Copiar mensaje'
        },
        hide: {
          tooltip: 'Ocultar en Clarice.ai'
        },
        view: {
          tooltip: 'Mostrar en Clarice.ai'
        }
      },
      prompts: {
        title: 'Prompts',
        options: {
          one: 'Crea una lista de 10 títulos de entradas de blog',
          two: 'Escribe un párrafo sobre',
          three: 'Ayúdame a crear una historia sobre',
          four: 'Escribe una canción sobre',
          five: 'Resume el siguiente texto',
          six: 'Escribe un anuncio de Facebook para las fiestas de fin de año',
          seven: 'Escribe una entrada de blog sobre',
          eight: 'Desarrolla una pequeña historia sobre'
        }
      }
    },
    documents: {
      search: {
        label: 'Buscar',
        placeholder: 'Buscar por título...'
      },
      action: {
        create: 'Nuevo Texto',
        update: 'Editar',
        delete: 'Eliminar'
      },
      loading: 'Cargando...',
      notFound: 'No se encontraron textos...',
      notAdded: 'No se ha agregado ningún texto hasta el momento...',
      notAddedText:
        'Aún no has agregado ningún texto aquí. ¿Qué tal empezar ahora?',
      updated: 'Actualizado',
      untitled: 'Sin título',
      modal: {
        delete: {
          title: 'Eliminar Documento',
          description: '¿Estás seguro de que deseas eliminar este documento?',
          action: {
            cancel: 'Cancelar',
            confirm: 'Eliminar'
          }
        }
      },
      limit: {
        free: {
          equal: {
            one: 'Has alcanzado el límite de textos guardados. Para agregar nuevos textos',
            two: 'hazte PRO'
          },
          greater: ''
        },
        paid: {
          equal: {
            one: 'Has alcanzado el límite de textos guardados. Por ahora solo puedo guardar',
            two: 'textos en la versión PRO.'
          },
          greater: {
            one: 'Ya has alcanzado el límite de textos guardados. Para agregar nuevos textos,',
            two: 'actualiza tu plan'
          }
        }
      }
    },
    review: {
      form: {
        title: {
          label: 'Título',
          placeholder: 'Agrega un título.'
        },
        editor: {
          label: 'Editor',
          placeholder: 'Pega tu texto aquí o presiona "/" para escribir con IA.'
        }
      },
      action: {
        viewDetails: 'Ver Detalles',
        summary: 'Resumir',
        copy: 'Copiar Texto',
        process: {
          label: 'Generar sugerencia',
          tooltip: 'Procesar Texto',
          loading: 'Cargando'
        }
      },
      metrics: {
        words: 'Palabras',
        characters: 'Caracteres',
        sentences: 'Frases',
        paragraphs: 'Párrafos'
      },
      score: {
        title: 'Puntuación del Texto'
      },
      suggestion: {
        category: {
          all: {
            name: 'Todas las sugerencias'
          },
          correction: {
            name: 'Corrección',
            tooltip: 'Mejora la ortografía, gramática y puntuación.'
          },
          clarity: {
            name: 'Claridad',
            tooltip: 'Ayuda a que tu escritura sea más fácil de entender.'
          },
          conciseness: {
            name: 'Concisión',
            tooltip: 'Transmite el mensaje de forma directa y clara.'
          },
          strength: {
            name: 'Fuerza',
            tooltip: 'Potencia el impacto y la persuasión de tu texto.'
          },
          originality: {
            name: 'Originalidad',
            tooltip:
              'Ayuda a crear un texto único y auténtico, ofreciendo ideas frescas y creativas.'
          },
          premium: {
            name: 'PRO',
            tooltip:
              'Lleva tu escritura a otro nivel con correcciones impecables, claridad excepcional y resultados impactantes.'
          }
        }
      },
      card: {
        accept: 'Corregido',
        dismiss: 'Descartar',
        add: 'Agregar',
        disable: 'Desactivar regla',
        falsePositive: 'Falso positivo'
      },
      language: {
        title: 'Idioma de Revisión',
        options: {
          portuguese: 'Portugués',
          english: 'Inglés',
          spanish: 'Español'
        }
      }
    },
    tools: {
      title: 'Herramientas',
      list: {
        review: {
          title: 'Revisar Texto',
          description: 'Obtén sugerencias para mejorar tu texto.'
        },
        rewriteText: {
          title: 'Reescribir Texto',
          description: 'Reescribe el texto sin cambiar su significado original.'
        },
        contentSummarizer: {
          title: 'Resumir Texto',
          description: 'Obtén un resumen con los puntos clave de un texto.'
        },
        translate: {
          title: 'Traducir Texto',
          description: 'Traduce textos a diferentes idiomas.',
          fields: {
            content: {
              label: 'Texto',
              placeholder: 'Escribe o pega un texto para traducir...'
            },
            language: {
              input: {
                label: 'Idioma de Entrada',
                options: {
                  detect: 'Detectar Idioma',
                  portuguese: 'Portugués',
                  english: 'Inglés',
                  spanish: 'Español'
                }
              },
              output: {
                label: 'Idioma de Salida',
                options: {
                  portuguese: 'Portugués',
                  english: 'Inglés',
                  spanish: 'Español'
                }
              }
            }
          },
          action: {
            generate: 'Traducir'
          }
        },
        aiDetect: {
          title: 'Detector de IA',
          description: 'Verifica si un texto fue generado por IA.',
          fields: {
            content: {
              label: 'Texto',
              placeholder: 'Escribe o pega un texto...'
            },
            language: {
              input: {
                label: 'Idioma de Entrada',
                options: {
                  detect: 'Detectar Idioma',
                  portuguese: 'Portugués',
                  english: 'Inglés',
                  spanish: 'Español'
                }
              },
              output: {
                label: 'Idioma de Salida',
                options: {
                  portuguese: 'Portugués',
                  english: 'Inglés',
                  spanish: 'Español'
                }
              }
            }
          },
          action: {
            generate: 'Analizar Texto'
          },
          result: {
            title: 'del texto es probablemente generado por IA',
            ai: 'Generado por IA',
            human: 'Escrito por humano'
          }
        }
      }
    },
    agents: {
      title: 'Agentes'
    },
    plans: {
      title: 'Prueba gratis por 7 días',
      description:
        'Y encuentra el plan más adecuado para las necesidades de tu negocio',
      securePayment: 'Pago garantizado y seguro',
      actions: {
        freeTrial: 'Iniciar prueba gratis ➜',
        buyNow: 'Comprar ahora ➜',
        contact: 'Contáctanos ➜'
      },
      consult: 'Consultar',
      customizedCharges: 'Cargos personalizados',
      loading: 'Cargando...',
      toggle: {
        monthly: 'Mensual',
        yearly: 'Anual'
      },
      bestPlan: 'Mejor Plan',
      saveMessage: '<strong>Ahorra 3 MESES</strong> con el Plan Anual',
      billedAnnually: 'Facturado anualmente',
      recurringPayment: 'Pago recurrente',
      perMonth: 'por mes en el <b>Plan Mensual</b>',
      wordLimit: '100 mil palabras por mes',
      wordUnlimited: 'Sin límite de palabras',
      month: 'mes',
      currency: '€',
      creative: {
        title: 'Creativo',
        subtitle:
          'Para creadores de contenido que necesitan escalar su trabajo',
        description:
          'Con las sugerencias avanzadas de Clarice.ai, ve más allá de la gramática y garantiza una escritura clara, concisa, fuerte y original.',
        benefits: {
          one: 'IA Revisora + IA Editora + IA Escritora',
          two: '65+ plantillas',
          three: 'ChatGPT',
          four: 'Plugin de Google Docs',
          five: 'Hasta 50 textos',
          six: 'Hasta 10 proyectos',
          seven: 'Soporte por correo electrónico'
        }
      },
      professional: {
        title: 'Profesional',
        subtitle:
          'Todo lo que necesitas para escribir 10 veces más rápido profesionalmente',
        description:
          'Con las herramientas de IA Revisora e IA Escritora, tienes soluciones poderosas para escribir y revisar textos y contenido completo.',
        benefits: {
          one: 'IA Revisora + IA Editora + IA Escritora',
          two: '65+ plantillas',
          three: 'ChatGPT',
          four: 'Plugin de Google Docs',
          five: 'Textos ilimitados',
          six: 'Proyectos ilimitados',
          seven: 'Soporte Premium'
        }
      },
      company: {
        title: 'Empresa',
        subtitle:
          'Para empresas que necesitan IA segura, escalable y personalizada',
        description:
          'Potencia tus resultados con Clarice.ai mediante paquetes personalizados de créditos/usuarios y soporte exclusivo.',
        benefits: {
          one: 'IA Revisora + IA Editora + IA Escritora',
          two: 'Manual de Marca',
          three: 'Mínimo de 5 miembros',
          four: 'Facturación centralizada',
          five: 'Plugin de Google Docs',
          six: 'Gestión de equipos',
          seven: 'Capacitación y Soporte Premium'
        }
      },
      lite: {
        monthly: {
          title: 'Mensual'
        },
        semiAnnual: {
          title: 'Semestral'
        },
        yearly: {
          title: 'Anual'
        }
      },
      discount: {
        ANUAL40: {
          description:
            'Usa el cupón <b>ANUAL40</b> y obtén un 40% de descuento en la suscripción anual. ¡Aprovecha! 🎉'
        },
        NIVER40: {
          description:
            'Usa el cupón <b>ANUAL40</b> y obtén un 40% de descuento en la suscripción anual. ¡Aprovecha! 🎉'
        },
        NIVER60: {
          description:
            'Usa el cupón <b>NIVER60</b> y obtén un 60% de descuento en la suscripción. ¡Aprovecha! 🎉'
        }
      }
    }
  },
  standard: {
    channels: {
      one: '¿Necesitas ayuda?',
      two: 'Visita nuestros canales'
    },
    button: {
      back: 'Volver'
    }
  },
  notify: {
    profile: {
      update: {
        success: '¡Perfil actualizado con éxito!'
      }
    },
    editProfile: {
      success: '¡Datos del perfil actualizados con éxito!'
    },
    project: {
      create: {
        success: '¡Proyecto creado con éxito!'
      },
      update: {
        success: '¡Proyecto actualizado con éxito!'
      },
      delete: {
        success: '¡Proyecto eliminado con éxito!'
      }
    },
    brand: {
      create: {
        required: {
          all: '¡Rellena todos los campos!'
        },
        loading: 'Creando la marca...',
        success: '¡Marca creada con éxito!',
        error: 'Ocurrió un error, intenta nuevamente'
      },
      update: {
        required: {
          all: '¡Por favor, rellena todos los campos!'
        },
        loading: 'Editando la marca...',
        success: '¡Marca editada con éxito!',
        error: 'Ocurrió un error, por favor intenta nuevamente.'
      },
      delete: {
        success: '¡Marca eliminada con éxito!'
      },
      generateWithIA: {
        required: {
          all: '¡Rellena todos los campos!',
          description: '¡Rellena el campo de descripción!'
        },
        loading: 'Creando la marca con IA...',
        success: '¡Marca creada con éxito!',
        error: 'Ocurrió un error, intenta nuevamente'
      }
    },
    rule: {
      enable: {
        success: '¡Regla reactivada con éxito!'
      }
    },
    dictionary: {
      add: {
        success: '¡Palabra añadida con éxito!'
      },
      delete: {
        success: '¡Palabra eliminada con éxito!'
      }
    },
    suggestion: {
      required: {
        all: '¡Por favor, rellena todos los campos!'
      },
      success: '¡Gracias por la sugerencia! :)'
    },
    business: {
      create: {
        success: '¡Negocio creado con éxito!'
      },
      update: {
        success: '¡Negocio actualizado con éxito!'
      },
      user: {
        add: {
          required: {
            all: '¡Por favor, rellena todos los campos!'
          },
          success: '¡Invitación enviada con éxito!'
        },
        delete: {
          success: '¡Usuario eliminado con éxito!'
        }
      }
    },
    backoffice: {
      business: {
        create: {
          required: {
            all: '¡Por favor, rellena todos los campos!'
          },
          success: '¡Empresa creada con éxito!'
        },
        update: {
          required: {
            all: '¡Por favor, rellena todos los campos!',
            name: '¡Por favor, ingresa el nombre de la empresa!',
            licenses: '¡Por favor, ingresa la cantidad de licencias!',
            startDate: '¡Por favor, ingresa la fecha de inicio!',
            endDate: '¡Por favor, ingresa la fecha final!'
          },
          success: '¡Empresa actualizada con éxito!'
        },
        delete: {
          success: '¡Empresa eliminada con éxito!'
        },
        user: {
          add: {
            required: {
              all: '¡Por favor, rellena todos los campos!'
            },
            success: '¡Usuario añadido con éxito!'
          },
          delete: {
            success: '¡Usuario eliminado con éxito!'
          }
        }
      }
    },
    templates: {
      create: {
        required: {
          all: '¡Complete todos los campos!'
        },
        loading: 'Procesando respuesta...',
        success: '¡Respuesta generada con éxito!',
        error: 'Ocurrió un error, por favor intente de nuevo.'
      },
      copyText: '¡Texto copiado con éxito!',
      createText: '¡Tu texto ha sido creado!'
    },
    tools: {
      translate: {
        required: {
          all: '¡Por favor, complete todos los campos!'
        },
        loading: 'Traduciendo texto...',
        success: '¡Texto traducido con éxito!',
        error: 'Ocurrió un error, por favor intente de nuevo.'
      },
      aiDetect: {
        required: {
          all: '¡Por favor, complete todos los campos!'
        },
        loading: 'Analizando texto...',
        success: '¡Texto analizado con éxito!',
        error: 'Ocurrió un error, por favor intente de nuevo.'
      },
      copyText: '¡Texto copiado con éxito!'
    },
    chat: {
      required: {
        message: {
          notEmpty: '¡Por favor ingresa un mensaje!',
          maxLength: '¡Mensaje demasiado largo, máximo 2000 caracteres!'
        }
      },
      limit: '¡Has alcanzado el límite de créditos!'
    },
    documents: {
      delete: {
        success: '¡Documento eliminado con éxito!',
        error: 'Ocurrió un error al eliminar su documento, intente nuevamente.'
      }
    },
    mailConfirmation: {
      success: '¡Correo de confirmación reenviado con éxito!'
    }
  },
  sidebar: {
    route: {
      home: 'Inicio',
      plans: 'Planes',
      templates: 'Plantillas',
      chat: 'Chat',
      editText: 'Edición de Texto',
      projects: 'Proyectos',
      brands: 'Centro de Marca',
      createBrand: 'Crear Marca',
      updateBrand: 'Editar Marca',
      updateBusiness: 'Editar Negocio',
      backoffice: 'Backoffice',
      review: 'Revisar Texto',
      rewriter: 'Reescribir Texto',
      summarizer: 'Resumir Texto',
      translate: 'Traducir Texto',
      aiDetect: 'Detector de IA',
      disabledRules: 'Reglas Desactivadas',
      dictionary: 'Diccionario',
      account: {
        profile: 'Perfil',
        update: 'Editar Perfil',
        credits: 'Créditos y Facturación',
        business: 'Mi Negocio'
      },
      help: 'Enviar Retroalimentación',
      documents: 'Documentos',
      agents: 'Agentes'
    },
    projects: {
      title: 'Proyectos',
      action: {
        view: 'Ver Proyectos',
        new: 'Nuevo Proyecto'
      }
    },
    brands: {
      title: 'Marcas',
      action: {
        view: 'Ver Marcas',
        new: 'Nueva Marca'
      },
      notSelected: 'No seleccionada'
    },
    account: {
      profile: 'Perfil',
      business: 'Mi Negocio',
      backoffice: 'Backoffice',
      credits: 'Créditos y Facturación',
      logout: 'Cerrar sesión'
    },
    conversion: {
      generateWords: 'Palabras generadas',
      paid: 'Mejorar',
      free: 'Hazte PRO',
      freeDays: '7 Días Gratis'
    }
  },
  navbar: {
    useHistory: {
      mobile: 'Historial',
      desktop: 'Usar Historial'
    }
  },
  brand: {
    select: 'Selecciona una marca',
    tag: 'Marca seleccionada para la creación de contenido.'
  },
  modal: {
    acceptedTerms: {
      title: 'Términos de Uso, Política de Privacidad y Política de Pagos',
      info: `<div><p> ¡Hola! Nos gustaría informarte que hemos actualizado recientemente nuestros Términos de Uso, Política de Privacidad y Política de Pagos. <br/> <br/> Los cambios se realizaron para reflejar mejor nuestros servicios y garantizar una mayor transparencia y seguridad para ti, nuestro usuario. </p> <strong>¿Qué se ha actualizado?</strong> <br/> <br/> <strong>Términos de Uso</strong>: Hemos actualizado nuestros Términos de Uso para aclarar los derechos y responsabilidades de todos los usuarios de nuestra plataforma. <br/> <br/> <strong>Política de Privacidad</strong>: Revisamos nuestra Política de Privacidad para detallar cómo recopilamos, utilizamos y protegemos tu información personal. <br/> <br/> <strong>Política de Pagos</strong>: Realizamos ajustes en nuestra Política de Pagos para garantizar claridad sobre los procesos de facturación, reembolsos y otros aspectos financieros. <br /> <br /> Para continuar utilizando nuestros servicios, te pedimos que leas y aceptes los nuevos términos. <br/> <br/> <strong>Próximos Pasos</strong> <br/> <p style='margin-Left: 20px'> 1. Lee atentamente los nuevos Términos de Uso, Política de Privacidad y Política de Pagos. <br/> 2. Desplázate hasta el final de este documento. <br /> 3. Marca la casilla confirmando que has leído y aceptas los nuevos términos. <br/> 4. Haz clic en el botón Aceptar. </p> Si tienes alguna duda o necesitas más información, ponte en contacto con nuestro servicio de atención al cliente. <br/> <br/> Gracias por seguir utilizando Clarice.ai.</>`,
      checkBox: {
        one: 'He leído y acepto los',
        two: 'términos de uso',
        three: 'política de privacidad',
        four: 'política de pagos'
      },
      action: {
        confirm: 'Aceptar'
      }
    },
    disabledRules: {
      title: 'Reglas Desactivadas',
      notFound: 'No hay reglas desactivadas en este momento...',
      loading: 'Cargando...',
      action: {
        enable: 'Activar'
      }
    },
    dictionary: {
      title: 'Mi Diccionario',
      notFound: 'No se encontró ninguna palabra...',
      notAdded: 'Aún no se ha añadido ninguna palabra...',
      form: {
        word: {
          placeholder: 'Ingresa una palabra...'
        }
      },
      action: {
        add: 'Añadir',
        delete: 'Eliminar'
      }
    },
    suggestion: {
      title: 'Sugerencia',
      description: 'Escribe con los detalles que consideres necesarios.',
      form: {
        subject: {
          label: '¿Cuál es el asunto?'
        },
        description: {
          label: 'Cuéntame tu sugerencia con detalles :)'
        }
      },
      action: {
        cancel: 'Cancelar',
        send: 'Enviar'
      }
    },
    conversion: {
      free: {
        one: {
          title: '¡Tus créditos están por agotarse!',
          description:
            '¡No te preocupes! Con solo unos pocos clics, puedes adquirir uno de nuestros planes PRO y renovar tu límite. Recuerda: cuando tus créditos se acaben, no podrás generar más palabras con IA.'
        },
        two: {
          title: '¡Tus créditos se han agotado!',
          description:
            '¡No te preocupes! Con solo unos pocos clics, puedes adquirir uno de nuestros planes PRO y renovar tu límite. Recuerda: cuando tus créditos se acaben, no podrás generar más palabras con IA.'
        },
        three: {
          title: 'Tus créditos se han agotado :(',
          description:
            '¡No te preocupes! Con solo unos pocos clics, puedes adquirir uno de nuestros planes PRO y renovar tu límite.'
        },
        action: 'Hazte PRO',
        actionFreeDays: 'Obtén 7 días gratis!'
      },
      paid: {
        one: {
          title: '¡Tus créditos están por agotarse!',
          description:
            '¡No te preocupes! Con solo unos pocos clics, puedes actualizar tu plan y renovar tu límite. Recuerda: cuando tus créditos se acaben, no podrás generar más palabras con IA.'
        },
        two: {
          title: '¡Tus créditos están por agotarse!',
          description:
            '¡No te preocupes! Con solo unos pocos clics, puedes actualizar tu plan y renovar tu límite. Recuerda: cuando tus créditos se acaben, no podrás generar más palabras con IA.'
        }
      },
      three: {
        title: 'Tus créditos se han agotado :(',
        description:
          '¡No te preocupes! Con solo unos pocos clics, puedes actualizar tu plan y renovar tu límite.'
      },
      action: 'Mejorar plan'
    },
    summary: {
      title: 'Resumen',
      notText:
        'Por favor, proporciona un texto para que se pueda realizar el proceso de resumen.'
    },
    onboarding: {
      step: {
        one: {
          title: 'Selecciona una palabra para buscar sinónimos',
          description:
            'La funcionalidad de <strong>Buscar Sinónimos</strong> enriquece tus textos al proporcionar sugerencias de palabras con significados similares.'
        },
        two: {
          title:
            'Resalta un fragmento del texto y recibe sugerencias de edición y mejora',
          description:
            'Utiliza los <strong>Ajustes con IA</strong> para simplificar, acortar, mejorar y cambiar el tono y la voz de tu texto en un instante.'
        },
        three: {
          title: 'Corrige desviaciones automáticamente con la ayuda de la IA',
          description:
            'Con la <strong>Corrección Automática</strong> recibirás sugerencias para corregir cada desviación del texto con un solo clic.'
        },
        four: {
          title:
            'Escribe “/” y observa cómo Clarice.ai sugiere ideas creativas.',
          description:
            '¿Bloqueo creativo? Usa la <strong>Escritura con IA</strong> para superar los obstáculos creativos. Solo una pausa para el café ☕.'
        }
      },
      action: {
        close: 'Cerrar',
        next: 'Siguiente'
      }
    },
    score: {
      performance: {
        title: 'Rendimiento',
        description:
          'La puntuación al lado representa la calidad de la escritura en este documento. Puedes mejorarla siguiendo las sugerencias de Clarice.ai.'
      },
      metrics: {
        title: 'Métricas del Texto',
        options: {
          paragraphs: 'Párrafos',
          sentences: 'Frases',
          words: 'Palabras',
          characters: 'Caracteres',
          readingTime: 'Tiempo de Lectura',
          speakingTime: 'Tiempo de Habla'
        }
      },
      readability: {
        title: 'Legibilidad',
        options: {
          wordLength: 'Promedio de Longitud de las Palabras',
          sentenceLength: 'Promedio de Palabras por Frase',
          readabilityScore: {
            title: 'Puntuación',
            description:
              'La puntuación va de 0 a 100. Las puntuaciones altas indican que el texto es fácil de leer.'
          }
        }
      },
      legibility: {
        title: 'Legibilidad',
        options: {
          wordLength: 'Promedio de Longitud de las Palabras',
          sentenceLength: 'Promedio de Palabras por Frase',
          complexWord: {
            title: 'Palabras Complejas',
            description:
              'Medimos la profundidad del vocabulario identificando palabras que no están entre las 5,000 más usadas en portugués.'
          },
          readabilityScore: {
            title: 'Puntuación de Lectura',
            description:
              'La puntuación va de 0 a 100. Las puntuaciones altas indican que el texto es fácil de leer.'
          },
          formality: 'Formalidad',
          genre: 'Género',
          sentiment: 'Sentimiento'
        }
      },
      time: {
        minutes: 'min',
        seconds: 'seg'
      }
    }
  },
  banner: {
    discount: {
      ANUAL40: {
        title: '¡40% de descuento! 🎉',
        description:
          'Usa el cupón <b>ANUAL40</b> y obtén un 40% de descuento en la suscripción anual. ¡Aprovecha!'
      },
      NIVER40: {
        title: '¡40% de descuento! 🎉',
        description:
          'Usa el cupón <b>NIVER40</b> y obtén un 40% de descuento en la suscripción anual. ¡Aprovecha!'
      },
      NIVER60: {
        title: '¡60% de descuento! 🎉',
        description:
          'Usa el cupón <b>NIVER60</b> y obtén un 60% de descuento en la suscripción. ¡Aprovecha!'
      },
      LINGUAPT50OLD: {
        title:
          'El 5 de mayo celebramos el <b><br />Día Mundial de la Lengua Portuguesa!</b> 🌍🎉',
        description:
          'Usa el cupón <b>LINGUAPT50</b> y obtén un 50% de descuento en la suscripción anual. ¡Aprovecha!'
      },
      LINGUAPT50: {
        title: 'Semana Nacional de la Lengua Portuguesa 📚🎉',
        description:
          'Usa el cupón <b>LINGUAPT50</b> y obtén un 50% de descuento en la suscripción anual. ¡Aprovecha!'
      },
      WS50: {
        title: 'Clarice.ai en el Web Summit 🥳',
        description:
          'Utiliza el código <b>WS50</b> y obtén un 50% de descuento en la suscripción anual. ¡Aprovecha!'
      },
      action: 'Suscribirse ahora'
    },
    googleDocs: {
      title: '¡Clarice en Google Docs!',
      description:
        'Prueba nuestra extensión para Google Docs. Instálala y recibe retroalimentación de Clarice.ai de inmediato.',
      action: 'Instalar Gratis'
    }
  },
  shortcut: {
    writer: {
      description: 'Pídele a Clarice que escriba...',
      option: '',
      loading: 'Clarice está escribiendo...'
    },
    complete: {
      description: 'Completar texto',
      option: '',
      loading: 'Completando el texto...'
    },
    createParagraph: {
      description: 'Redactar párrafo',
      option: 'Escribe un párrafo sobre',
      loading: 'Redactando párrafo...'
    },
    brainstorm: {
      description: 'Generar ideas',
      option: 'Generar ideas sobre',
      loading: 'Generando ideas...'
    },
    blogPost: {
      description: 'Crear entrada de blog',
      option: 'Escribe una entrada de blog sobre'
    },
    newGenerate: {
      action: 'Generar nuevas sugerencias',
      loading: 'Generando nuevas sugerencias...'
    },
    fields: {
      text: {
        label: 'Pídele a la IA que escriba lo que quieras...',
        placeholder: 'Pídele a la IA que escriba lo que quieras...'
      }
    },
    simplify: {
      description: 'Simplificar',
      loading: 'Simplificando texto...'
    },
    shorten: {
      description: 'Acortar',
      loading: 'Acortando texto...'
    },
    better: {
      description: 'Mejorar',
      loading: 'Mejorando texto...'
    },
    tone: {
      description: 'Cambiar tono',
      loading: 'Cambiando el tono...',
      options: {
        back: 'Volver',
        friendly: 'Tono amigable',
        assertive: 'Tono asertivo',
        constructive: 'Tono constructivo',
        descriptive: 'Tono descriptivo',
        happy: 'Tono alegre',
        modest: 'Tono modesto',
        professional: 'Tono profesional',
        neutral: 'Tono neutral',
        optimistic: 'Tono optimista',
        reflective: 'Tono reflexivo'
      }
    }
  }
};

export default LANGUAGE_ES;
