import React, { useContext } from 'react';
import { InfoInterface } from './interface';
import { Box, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import useStyles from './style';
import { useReduxState } from '../../../../../../hooks/useReduxState';

const Info = ({}: InfoInterface) => {
  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  const { user }: any = useReduxState();

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>BLACK FRIDAY</h1>

      <img
        src={baseURL + 'discount.svg'}
        alt="Emoji Clarice"
        style={{ width: '11rem' }}
      ></img>

      <p className={classes.description}>Cupom: CLARICE50</p>
    </div>
  );
};

export default Info;
